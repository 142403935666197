import React, {useCallback, useEffect, useState} from 'react';
import {withStyles} from '@jpmuitk/theme';
import {Checkbox} from '@jpmuitk/checkbox';
import {Button} from "@jpmuitk/button";
import {ColumnLayout} from "@jpmuitk/column-layout";
import DOMPurify from 'dompurify';
import {Card} from "@jpmuitk/card";
import {saveUserConsent} from "./common/api/AuditApi";

const styles = {
  displayPanel: {
    maxWidth: '1600px',
  },
  main: {
    height: '100%',
    width: '66%',
    margin: 'auto',
    overflow: 'auto',
  },
  title: {
    color: '#000000',
    textAlign: 'center',
  },
  errors: {
    color: '#FF0000',
    textAlign: 'center',
  },
  header: {
    backgroundColor: '#EDEBED',
    padding: '10px 25px',
    textDecoration: 'overline #2AB1FA solid 7px',
    fontWeight: 'normal',
    color: '#5B5D5E',
  },
  textbox: {
    border: '1px solid #d0d9e0',
    shadow: '10px',
  },
  checkbox: {
    padding: '10px',
  },
  button: {
    float: 'right',
  },
  height: {height: '100%'},
  flex: {flex: 1},
};

const PrivacyAgreementContainer = ({
  classes, profileConfiguration, setIsAgreementOpen,
  setIsVerificationOpen, setUserConsent, accessToken, sessionToken
}) => {

  const [agreementContent, setAgreementContent] = useState(false);
  const [clientAgreed, setClientAgreed] = useState(false);
  const [errorMessage,setErrorMessage] = useState("");

  useEffect(() => {
    setAgreementContent(profileConfiguration?.privacyAgreement?.agreementContent)
  })

  const handleAgreementChange = useCallback(() => {
    console.log(event.target.checked)
    setClientAgreed(event.target.checked)
  }, [])

  const handleSubmit = useCallback((event,profileConfiguration, accessToken, sessionToken) => {
    const currentDateTime = new Date().toISOString()
    const userConsent = {consent: {obtained: "yes", obtainedAt: currentDateTime}}
    saveUserConsent(currentDateTime, accessToken, sessionToken)
      .then(() => {
        console.log("User consent received at: ", currentDateTime);
        setIsAgreementOpen(false);
        setIsVerificationOpen(true);
        setUserConsent(userConsent);
      })
      .then(() => {
        console.log("Error saving user consent");
        setErrorMessage(" Error with Account Creation ");
      })
  }, [])

  return (
    <main aria-labelledby="PrivacyAgreement" className={classes.main}>
        <h2 className={classes.title}>TERMS AND CONDITIONS</h2>
        <h2 className={classes.header}>PERSONAL DATA PRIVACY STATEMENT</h2>
        <ColumnLayout lg={12} sm={12} xs={12} md={12}>
          <Card name="privacyAgreementContent" id="privacyAgreementContent"
                style={{paddingRight: '0', borderRadius: '5px'}}
                render={() => (
                  <div style={{maxHeight: '40rem', overflowY: "auto"}}
                       dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(agreementContent)}}>
                  </div>
                )}
          />
        </ColumnLayout>
        <ColumnLayout container>
          <ColumnLayout item xs={9} sm={9} md={9} lg={9}>
            <Checkbox className={classes.checkbox}
                      name="userAgreementCheckbox" id="userAgreementCheckbox"
                      onChange={handleAgreementChange}
                      label="I have read and understood the above Personal Data Privacy Statement
                       and agree to its terms."
            />
          </ColumnLayout>
          <ColumnLayout className={classes.button}
                        style={{textAlign: 'end'}}
                        item xs={3} sm={3} md={3} lg={3}>
            <Button
              name="userAgreeSubmitBtn" id="userAgreeSubmitBtn"
              style={{marginTop: '0.5rem'}}
              variant="cta"
              disabled={!clientAgreed}
              onClick={(event) => handleSubmit(event,profileConfiguration, accessToken, sessionToken)}
            >Submit</Button>
          </ColumnLayout>
        </ColumnLayout>
        <p className={classes.errors}> {errorMessage} </p>
    </main>
  );
};

export default withStyles(styles)(PrivacyAgreementContainer);
