const uri = window.location.origin;

export const saveUserConsent = (currentDateTime, access_token, session_token) => {
  let request = {
    obtainedAt: currentDateTime,
    obtained: true
  };
  return fetch(`${uri}/backend-vald/v1/doc/user-consent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + access_token,
      'SessionToken': session_token,
    },
    body: JSON.stringify(request),
  });
};
