import React, {useCallback, useEffect, useState} from 'react';
import {withStyles} from '@jpmuitk/theme';
import {Button} from "@jpmuitk/button";
import {SteppedTracker, TrackerStep} from "@jpmuitk/stepped-tracker";
import {Card} from "@jpmuitk/card"
import { CodeBlock } from '@jpmuitk/code-block';
import {Panel} from "@jpmuitk/panel";
import {ColumnLayout} from "@jpmuitk/column-layout";
import {Icon} from "@jpmuitk/icon";
import {FormField} from '@jpmuitk/form-field';
import {Dropdown} from '@jpmuitk/dropdown';
import {createClientAccount} from "./redux/clientActions";
import {Spinner} from '@jpmuitk/spinner';
import {AriaAnnouncerProvider} from '@jpmuitk/aria-announcer';
import {initCallbackForAccount} from "./common/api/ClientApi";
import {getCurrentEnv} from "./common/utility";
import {logErrorMessage} from "./redux/loggingActions";

const styles = {
  displayPanel: {
    maxWidth: '1600px',
  },
  main: {
    height: '100%',
    overflow: 'auto',
  },
  verificationColumn: {
    minHeight: '480px',
    minWidth: '535px'
  },
  verificationContainer: {
    minWidth: '100%',
    minHeight: '100%',
    margin: 'auto',
    borderRadius: '5px'
  },
  startButton: {
    width: '100%',
    margin: 'auto',
    borderRadius: '5px'
  },
  height: {height: '100%'},
  flex: {flex: 1},
};

const ValidationContainer = ({
                               classes, profileConfiguration, userConsent,
                               countries, setAccountResponse, accountResponse,
                               accessToken, sessionToken
                             }) => {

  const programIdType = "AVS";
  const [activeStep, setActiveStep] = useState(0);
  const [isAccountErrorScreen, setIsAccountErrorScreen] = useState(false);
  const [isErrorScreen, setIsErrorScreen] = useState(false);

  const errorScreenStatus = {
    status: 'error',
    message: 'An error has occurred in this step',
  };

  const errorCreatingAccount = {
    status: 'error',
    message: 'An error has occurred in this step',
  };

  const handleNext = useCallback(
    () => {
      setActiveStep(Math.min(activeStep + 1, steps.length ));
      console.log("activeStep" + activeStep);
    }, [activeStep]
  );

  const handleAccountError = useCallback(
    () => {
      setIsAccountErrorScreen(true);
    },
    [isAccountErrorScreen]
  );
  const handleVerifError = useCallback(
    () => {
      setIsErrorScreen(true);
    },
    [isErrorScreen]
  );


  const steps = ['Start Verification', 'Identification & Verification (ID&V)'];

  return (
    <main aria-labelledby="Validation" className={classes.main}>
      <ColumnLayout container>
        <ColumnLayout item xs={12}>
          <SteppedTracker activeStep={activeStep} style={{marginBottom: '2em'}}>
            <TrackerStep validationState={isAccountErrorScreen ? errorCreatingAccount : {status: ''}}>Start Verification</TrackerStep>
            <TrackerStep validationState={isErrorScreen ? errorScreenStatus : {status: ''}}>Identification & Verification (ID&V)</TrackerStep>
          </SteppedTracker>
        </ColumnLayout>
        <ColumnLayout item xs={3}/>
        <ColumnLayout item xs={6} className={classes.verificationColumn}>
          <Card className={classes.verificationContainer}>
            {(activeStep === 0) && <StartVerification classes={classes} handleNext={handleNext}
                                                      onError={handleAccountError}
                                                      userConsent={userConsent}
                                                      accountResponse={accountResponse}
                                                      setAccountResponse={setAccountResponse}
                                                      profileConfiguration={profileConfiguration}
                                                      countries={countries}
                                                      accessToken={accessToken}
                                                      sessionToken={sessionToken}/>}
            {(activeStep >= 1) && <IdvIntegration accountResponse={accountResponse}
                                                  handleNext={handleNext}
                                                  showErrorScreen={handleVerifError}
                                                  accessToken={accessToken}
                                                  sessionToken={sessionToken}/>}
          </Card>
        </ColumnLayout>
        <ColumnLayout item xs={3}/>
      </ColumnLayout>
    </main>
  );
};

const StartVerification = ({countries, profileConfiguration, userConsent,
                             setAccountResponse, classes, handleNext, onError, accessToken, sessionToken}) => {
  const [allowedDocumentTypes, setAllowedDocumentTypes] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedDocType, setSelectedDocType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCountryAndAllowedDocumentTypes(countries[0]);
  }, [])

  const setCountryAndAllowedDocumentTypes = useCallback(
    (country) => {
      let countryThreeCharCode = "";
      let firstDocType = "";
      if (!country){
        setAllowedDocumentTypes(profileConfiguration.docTypeByCountry[0].docTypesAllowed.map((docTypesAllowed) => docTypesAllowed.docTypeName));
        countryThreeCharCode = profileConfiguration.docTypeByCountry[0].country.threeCharCode;
      }
      else {
        let allowedTypes = [];
        if (profileConfiguration && profileConfiguration.docTypeByCountry) {
          profileConfiguration.docTypeByCountry.map((docTypeByCountry) => {
            if (docTypeByCountry.country.countryName === country) {
              allowedTypes = docTypeByCountry.docTypesAllowed.map((docTypesAllowed) => docTypesAllowed.docTypeName);
              firstDocType = docTypeByCountry.docTypesAllowed[0].docTypeName;
              countryThreeCharCode = docTypeByCountry.country.threeCharCode;
            }
          });
        }
        setAllowedDocumentTypes(allowedTypes);
        setSelectedDocType(firstDocType);
        setSelectedCountry(countryThreeCharCode);
      }
    }, [allowedDocumentTypes, selectedCountry, selectedDocType]
  );

  const createAccount = (event, country, docType) => {
    setIsLoading(true);
    createClientAccount(
      userConsent,
      country,
      docType, accessToken, sessionToken
    )
      .then((accountJson) => {
        setAccountResponse(accountJson.accountResponse);
        setIsLoading(false);
        handleNext();
      })
      .catch(() => {
        console.log("Error creating user account");
        onError();
        setIsLoading(false);
      })
  };

  return (
    <div style={{margin: '10%'}}>
      <ColumnLayout container>
        <ColumnLayout item xs={2}/>
        {/*<ColumnLayout item xs={2}><Button onClick={() => initCallbackForAccountfunction()}>TestCallback Request</Button></ColumnLayout>*/}
        <ColumnLayout item xs={8}>
          <div>
            <Panel name="startVerifHeader" id="startVerifHeader"
                   density={"high"} style={{textAlign: 'center'}}>
              <h1>
                <Icon accessibleText={"Shield Verification Icon"} name={"protection-solid"} size={"medium"}
                      style={{marginRight: '10px'}}/>
                <b>Start Verification</b>
              </h1>
            </Panel>
          </div>
          <div style={{marginBottom: '2em'}}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '300px' }}>
                <h3>Country</h3>
                <FormField label="Choose issuing country">
                  <Dropdown
                    name="countryDropdown" id="countryDropdown"
                    displayedItemCount={8}
                    initialSelectedItem={countries[0]}
                    onChange={(e, item) => {
                      setCountryAndAllowedDocumentTypes(item);
                    }}
                    source={countries}
                    width={120}
                  />
                </FormField>
              </div>
              <div style={{ width: '300px', marginLeft: 16 }}>
                <h3>Document Type</h3>
                <FormField label="Select ID type">
                  <Dropdown
                    name="docTypeDropdown" id="docTypeDropdown"
                    initialSelectedItem={allowedDocumentTypes[0]}
                    source={allowedDocumentTypes}
                    onChange={(e, item) => {
                      setSelectedDocType(item);
                    }}
                    selectedItem={selectedDocType}
                    width={120}
                  />
                </FormField>
              </div>
            </div>

          </div>
          <Button onClick={(event) => createAccount(event, selectedCountry, selectedDocType)}
                  name="startVerifButton" id="startVerifButton"
                  variant={"cta"} density={'touch'}
                  className={classes.startButton}
                  disabled={!selectedCountry || !selectedDocType || selectedDocType === "" || isLoading}>Start</Button>
          {isLoading ?
            <Panel density={"high"} style={{marginLeft: '45%'}}>
              <AriaAnnouncerProvider>
                <Spinner />
              </AriaAnnouncerProvider>
            </Panel> : null
          }
          <br/>
          <br/>
        </ColumnLayout>
        <ColumnLayout item xs={2}/>
      </ColumnLayout>
    </div>
  )
}

// Not extensively formatted since we're replacing it with the iFrame
const IdvIntegration = ({accountResponse,
                          handleNext, showErrorScreen, accessToken, sessionToken}) => {
  const [isSuccess, setIsSuccess] = useState(false);

  function receiveMessage(event) {
    var data = event.data;
    if (data != null && typeof data == 'string') {
      var dataObj = window.JSON.parse(data);

      console.log('ID Verification Web was loaded in an iframe.');
      console.log('event-type:', dataObj.eventType);
      console.log('date-time:', dataObj.dateTime);

      if (dataObj.payload) {
        console.log('value:', dataObj.payload.value);
        console.log('metainfo:', dataObj.payload.metainfo);
        if (dataObj.payload.value === 'success'){
          initCallbackForAccount(accountResponse.account.id,
            accountResponse.workflowExecution.id, accessToken, sessionToken)
            .then((response) => {
              console.log("Success initCallbackForAccount");
              setIsSuccess(true);
              handleNext();
            })
            .catch(() => {
              console.log("Error initCallbackForAccount");
              showErrorScreen();
              setIsSuccess(false);
            })
        }
        if (dataObj.payload.value === 'error'){
          logErrorMessage('Unexpected error loading Jumio iFrames for ID Verification. Please try again');
          showErrorScreen();
          setIsSuccess(false);
        }
      }

    }
  }
  window.addEventListener("message", receiveMessage, false);

  return (
    <div style={{height:'60vh', overflowY: 'auto'}}>
      <iframe src={accountResponse.web.href} width="100%" height="99%" frameBorder="0"
              allow="camera;fullscreen;accelerometer;gyroscope;magnetometer" allowfullscreen></iframe>
      {(isSuccess && getCurrentEnv() === 'LOCAL') ?
        <Card style={{borderRadius: '5px', marginTop: '5px'}}>
          <h2>
            <Icon accessibleText={"Shield Verification Icon"} name={"protection-solid"} size={"medium"}
                  style={{marginRight: '10px'}}/>
            <b>Response Payload Received by Clients:</b>
          </h2>
          <CodeBlock language="json">
            {/*TODO: Remove demo block*/}
          </CodeBlock>
        </Card> : null
      }
    </div>

  )

}

export default withStyles(styles)(ValidationContainer);
