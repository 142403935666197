import React, {useCallback, useEffect, useState} from 'react';
import {withStyles} from '@jpmuitk/theme';
import {ColumnLayout} from '@jpmuitk/column-layout';
import {Logo} from '@jpmuitk/logo';
import {getClientLogo} from "../redux/clientActions";
import {useParams} from "react-router-dom";
import Cookies from "js-cookie";

const styles = {
  rootBox: {
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
  },
  banner: {
    paddingLeft: '16px',
    height: '44px',
    background: '#ffffff',
    maxWidth: '1600px',
  },
  header: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#161616',
    paddingTop: '5px',
  },
  tabs: {
    paddingRight: 0,
  },
  tab: {
    paddingTop: '1rem',
  },
  tabIcon: {
    marginRight: '0.5rem',
  },
};

const Banner = ({classes, profileConfiguration, accessToken, sessionToken}) => {

  const defaultBannerColor = '#936946';

  const [fileWrapper, setFileWrapper] = useState()
  const [clientBannerColor, setClientBannerColor] = useState("#FFF")
  const [useDefaultBranding, setUseDefaultBranding] = useState(false)
  const [bannerHeight, setBannerHeight] = useState('44px')
  const {clientId, programId, profileName} = useParams()

  useEffect(() => {
    getClientLogo(accessToken, sessionToken)
      .then((response) => response.json())
      .then(async (fileWrapper) => {
        if (fileWrapper.resource.length < 1) {
          setUseDefaultBranding(true)
          setClientBannerColor(defaultBannerColor)
        } else {
          setFileWrapper(fileWrapper)
          setClientBannerColor(profileConfiguration ? profileConfiguration.primaryColor : defaultBannerColor)
          setUseDefaultBranding(false)
        }
      })
      .catch((error) => {
        console.log("Got error rendering logo: ", error);
        setUseDefaultBranding(true)
        setClientBannerColor(defaultBannerColor)
      })
  }, [clientId, programId, profileName, profileConfiguration])


  return (
    <header style={{
      borderBottom: '3px solid' + (useDefaultBranding ? defaultBannerColor : clientBannerColor),
      height: bannerHeight
    }} className={classes.rootBox}>
      <ColumnLayout
        alignContent="center"
        className={classes.banner}
        container
        justify="space-between"
      >
        <ColumnLayout item>
          <ClientLogo useDefaultBranding={useDefaultBranding} fileWrapper={fileWrapper}
                      setBannerHeight={setBannerHeight}></ClientLogo>
        </ColumnLayout>
      </ColumnLayout>
    </header>
  );
};

const ClientLogo = ({useDefaultBranding, fileWrapper, setBannerHeight}) => {
  const whiteSpaceAboveLogo = 20
  const renderDefaultLogo = useCallback(() => <Logo density={'low'}/>, [])
  const adjustBannerHeight = useCallback(({target: img}) => {
    const adjustedHeight = img.naturalHeight + whiteSpaceAboveLogo
    setBannerHeight(adjustedHeight)
  }, [setBannerHeight])

  const renderClientLogo = useCallback(() => {
    if (fileWrapper) {
      let source = "data:"
      source += fileWrapper.contentType
      source += ";base64,"
      source += fileWrapper.resource
      return <img onLoad={adjustBannerHeight} src={source} alt={"BrandLogo"} style={{marginTop: '20px', marginBottom: '10px'}}/>
    } else {
      renderDefaultLogo()
    }
  }, [fileWrapper])

  return (
    <>
      {useDefaultBranding && renderDefaultLogo()}
      {!useDefaultBranding && renderClientLogo()}
    </>
  )
}

export default withStyles(styles)(Banner);

