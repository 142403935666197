const uri = window.location.origin;

export const logErrorEvent = data => {
  return fetch(`${uri}/backend-vald/logerror`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  });
};
