import React, {useEffect, useState} from 'react';
import {withStyles} from '@jpmuitk/theme';
import {useParams, useSearchParams} from "react-router-dom";
import {ColumnLayout} from "@jpmuitk/column-layout";
import Banner from "./common/Banner";
import {Panel} from "@jpmuitk/panel";
import PrivacyAgreementContainer from "./PrivacyAgreementContainer";
import ValidationContainer from "./ValidationContainer";
import {getProfileConfiguration} from "./redux/clientActions";
import Cookies from "js-cookie";
import {Card} from "@jpmuitk/card";
import {Spinner} from "@jpmuitk/spinner";
import {AriaAnnouncerProvider} from "@jpmuitk/aria-announcer";

const styles = {
  root: {
    background: '#F2F4F6',
    height: '100%',
    margin: '0 auto',
    overflow: 'auto',
  },
  panel: {
    flex: 1,
    overflow: 'auto',
  },
  mainContent: {
    flex: 1,
    // maxWidth: '1600px',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    overflow: 'auto',
  },
  columnPanel: {
    textAlign: 'center',
    paddingTop: '5rem',
    paddingBottom: '5rem',
  },
};

const Landing = ({
                   classes
                 }) => {

  const {root, panel, mainContent} = classes;
  const [accessToken, setAccessToken] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const [authorized, isAuthorized] = useState(false);
  const [loading, isLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [profileConfiguration, setProfileConfiguration] = useState();
  const [isAgreementOpen, setIsAgreementOpen] = useState(true);
  const [isVerificationOpen, setIsVerificationOpen] = useState(false);
  const [userCookieAcceptance, setUserCookieAcceptance] = useState(false);
  const [accountResponse, setAccountResponse] = useState();
  const [userConsent, setUserConsent] = useState();
  const [countries, setCountries] = useState([]);
  const programIdType = 'AVS';

  useEffect(() => {
    isLoading(true);
    const urlParams = new URLSearchParams(document.location.search)
    let access_token = urlParams.get("accessToken");
    let session_token = urlParams.get("sessionToken");

    if (access_token && session_token) {
      Cookies.set('PA.global', access_token);
      Cookies.set('JPMC_SESSION_TOKEN', session_token);
    } else {
      access_token = Cookies.get('PA.global');
      session_token = Cookies.get('JPMC_SESSION_TOKEN');
    }
    initCalls(access_token, session_token);
  }, [])

  function initCalls(access_token, session_token){
    let countryList = [];
    getProfileConfiguration(access_token, session_token)
      .then((response) => {
        if(response.status!==200) {
          throw new Error(response.status);
        }
        return response.json()
      })
      .then(async (profileConfiguration) => {
        isAuthorized(true);
        isLoading(false);
        setProfileConfiguration(profileConfiguration);
        setAccessToken(access_token);
        setSessionToken(session_token);

        if (profileConfiguration && profileConfiguration.docTypeByCountry) {
          countryList = profileConfiguration.docTypeByCountry.map((docTypeByCountry) => docTypeByCountry.country.countryName);
        }
        setCountries(countryList);
        setUserCookieAcceptance(true);
      })
      .catch((error) => {
        if (error.message.indexOf("401") !== -1) {
          isAuthorized(false);
          console.log("401 unauthorized session");
        }
        else {
          console.log(error);
        }
        isLoading(false);
      })
  }

  return (
    <ColumnLayout container className={root} direction="column">
      {authorized && accessToken && sessionToken && profileConfiguration
        ?
        <ColumnLayout item>
          <ColumnLayout item>
            <Banner profileConfiguration={profileConfiguration}
                    accessToken={accessToken}
                    sessionToken={sessionToken}/>
          </ColumnLayout>
          <ColumnLayout item className={mainContent}>
            <Panel className={panel}>
              {isAgreementOpen && !isVerificationOpen
                ? (<PrivacyAgreementContainer profileConfiguration={profileConfiguration}
                                              setIsAgreementOpen={setIsAgreementOpen}
                                              setIsVerificationOpen={setIsVerificationOpen}
                                              setUserConsent={setUserConsent}
                                              accessToken={accessToken}
                                              sessionToken={sessionToken}/>) : null}
              {!isAgreementOpen && isVerificationOpen
                ? (<ValidationContainer profileConfiguration={profileConfiguration}
                                        accountResponse={accountResponse}
                                        setAccountResponse={setAccountResponse}
                                        userConsent={userConsent}
                                        countries={countries}
                                        accessToken={accessToken}
                                        sessionToken={sessionToken}/>) : null}
            </Panel>
          </ColumnLayout>
        </ColumnLayout>
        :
          <ColumnLayout container spacing={3} style={{minHeight: '100vh'}}>
            <ColumnLayout item xs></ColumnLayout>
            <ColumnLayout item xs style={{paddingTop: '10%', paddingBottom: '10%'}}>
              { !loading ?
              <Card>
                <div>
                  <h1>Authentication Failed</h1>
                  <span>Your session has expired or is no longer valid.</span>
                </div>
              </Card> : null }
              { loading ?
              <div style={{paddingTop: '20px', paddingLeft: '47%'}}>
                <AriaAnnouncerProvider>
                  <Spinner/>
                </AriaAnnouncerProvider>
              </div> : null }
            </ColumnLayout>
            <ColumnLayout item xs></ColumnLayout>
          </ColumnLayout>
      }
    </ColumnLayout>
  );
};

export default withStyles(styles)(Landing);
