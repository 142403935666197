export const getCurrentEnv = () => {
  let env = window.location.origin;
  if (env.includes('local')) {
    return 'LOCAL';
  } else if (env.includes('dev')) {
    return 'DEV';
  } else if (env.includes('sandbox.test')) {
    return 'SANDBOX';
  } else if (env.includes('test')) {
    return 'UAT';
  } else {
    return 'PROD';
  }
};
