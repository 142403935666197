import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

export const store = createStore(
  composeEnhancers(applyMiddleware(thunk))
);

export const testStore = preloadedState =>
  createStore(
    reducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk))
  );
