import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {createToolkitTheme, ThemeProvider} from '@jpmuitk/theme';
import {AriaAnnouncerProvider} from '@jpmuitk/aria-announcer';
import {Provider} from 'react-redux';
import {store} from './components/redux/rootReducer';
import '@jpmuitk/style/css/jpmuitk.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const theme = createToolkitTheme('light');

function Root() {
  return (
    <AriaAnnouncerProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </AriaAnnouncerProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
