import * as api from '../common/api/LoggingApi';

// send error event for logging
export const logErrorMessage = message => {
  try {
    api.logErrorEvent(message)
      .then(response => {
        if (!response.ok) {
          throw new Error(response);
        }
      });
  } catch (error) {
    console.log(`An error occurred when attempting to log frontend errors: ${error.message}`);
  }
};
