import {fetchClientLogo, fetchProfileConfiguration,
  initClientAccount} from "../common/api/ClientApi";
import * as reducerConstants from './reducerConstants';
// This should go in a different package but for some reason it can't find ClientApi in a different package



// ------ SIMPLE ACTIONS ------ //

// Client
export const fetchUserSuccess = data => ({
  type: reducerConstants.FETCH_USER_SUCCESS,
  data,
});

export const fetchUserFailed = error => ({
  type: reducerConstants.FETCH_USER_FAILED,
  error,
});

// ------ COMPLEX ACTIONS ------ //

export async function createClientAccount (userConsent,country, docType, access_token, session_token) {
  const response = await initClientAccount(userConsent,country, docType, access_token, session_token);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(data));
  }
  else{
    return data;
  }
}

export async function getClientLogo (access_token, session_token) {
  return fetchClientLogo(access_token, session_token);
}

export async function getProfileConfiguration (access_token, session_token) {
  return fetchProfileConfiguration(access_token, session_token);
}
