import React, {useEffect, useState} from 'react';
import {withStyles} from '@jpmuitk/theme';
import {ColumnLayout} from "@jpmuitk/column-layout";
import { Spinner } from '@jpmuitk/spinner';
import { AriaAnnouncerProvider } from '@jpmuitk/aria-announcer';
import Cookies from "js-cookie";
import {useNavigate, useSearchParams} from "react-router-dom";

const styles = {
  root: {
    background: '#F2F4F6',
    height: '100%',
    margin: '0 auto',
    overflow: 'auto',
  }
};

const Access = ({classes}) => {
  const {root} = classes;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.hasStorageAccess().then((hasAccess) => {
      if (hasAccess) {
        console.log('on hasAccess = true: document.cookie=' + document.cookie);
        console.log('hasStorageAccess Cookies.get(PA.global=' + Cookies.get('PA.global'));
        console.log('hasStorageAccess Cookies.get(JPMC_SESSION_TOKEN=' + Cookies.get('JPMC_SESSION_TOKEN'));
        backToRoot();
      } else {
        // storage access hasn't been granted already;
        // you may want to call requestStorageAccess().
        document.requestStorageAccess().then((request) => {
          if (request){
            console.log('on requestStorageAccess = true: document.cookie=' + document.cookie);
            console.log('requestStorageAccess Cookies.get(PA.global= ' + Cookies.get('PA.global'));
            console.log('requestStorageAccess Cookies.get(JPMC_SESSION_TOKEN= ' + Cookies.get('JPMC_SESSION_TOKEN'));
            backToRoot();
          } else {
            //Default to URL Params
            let access_token = searchParams.get("accessToken");
            let session_token = searchParams.get("sessionToken");
            console.log('access_token = searchParams=' + access_token);
            console.log('session_token = searchParams=' + session_token);

            if (access_token != null && access_token !== '') {
              Cookies.set('PA.global', access_token);
              Cookies.set('JPMC_SESSION_TOKEN', session_token);
            }
            backToRoot();
          }
        });
      }
    });
  }, [])

  const backToRoot = () =>{
    let path = '/';
    navigate(path);
  }

  return (
    <ColumnLayout container direction="column">
      <ColumnLayout item xs={4} sm={4} md={4} lg={4}>
      </ColumnLayout>
      <ColumnLayout item xs={4} sm={4} md={4} lg={4}>
        <AriaAnnouncerProvider>
          <Spinner />
        </AriaAnnouncerProvider>
      </ColumnLayout>
      <ColumnLayout item xs={4} sm={4} md={4} lg={4}>
      </ColumnLayout>

    </ColumnLayout>
  );
};

export default withStyles(styles)(Access);
