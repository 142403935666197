import {createSelector} from 'reselect';

const getProfileFormStatus = state => state.profileForm.status;

const getUserStatus = state => state.user.status;

const getProfileFormError = state => state.profileForm.error;

const getUserError = state => state.user.error;

export const getIsFetching = createSelector(getUserStatus, userStatus =>
  [userStatus].includes('fetching')
);

export const getErrors = createSelector(getUserError, reportsError => {
  const errors = {
    ...(reportsError && { reportsError }),
  };

  return errors;
});

export const getProfileFormErrors = createSelector(
  getProfileFormError,
  reportsError => {
    const errors = {
      ...(reportsError && { reportsError }),
    };

    return errors;
  }
);

export const getProfielFormStatus = createSelector(
  getProfileFormStatus,
  reportsError => {
    const errors = {
      ...(reportsError && { reportsError }),
    };

    return errors;
  }
);
