const uri = window.location.origin;

export const initClientAccount = async (userConsent,country, docType, access_token, session_token) => {
  return fetch(`${uri}/backend-vald/initialize/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + access_token,
      'SessionToken': session_token,
    },
    body: JSON.stringify({
      userConsent: userConsent,
      country: country,
      docType: docType})
  });
}

export const initCallbackForAccount = async (accountId, workflowExecutionId, access_token, session_token) => {
  return fetch(`${uri}/backend-vald/initiate-callback/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + access_token,
      'SessionToken': session_token,
    },
    body: JSON.stringify({
      accountId: accountId,
      workflowExecutionId: workflowExecutionId
    })
  });
}

export const fetchClientLogo = async (access_token, session_token) => {
  return fetch(`${uri}/backend-vald/client/logo`, {
    method: 'GET',
    headers: {
      'Authorization': 'bearer ' + access_token,
      'SessionToken': session_token,
    }
  });
};

export const fetchProfileConfiguration = async (access_token, session_token) => {
  return fetch(`${uri}/backend-vald/client/whitelabel`, {
    method: 'GET',
    headers: {
      'Authorization': 'bearer ' + access_token,
      'SessionToken': session_token,
    }
  });
};
